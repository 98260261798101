<template>
  <div class="task-flies alone-possess" v-for="item in fileList" :key="item.id">
    <!-- PDF -->
    <div class="pdf" v-if="item.extension =='.pdf'">
      <!-- <p>{{item.file_name}}</p> -->
      <!-- /qddir/8719f9e9140bd347b5aeb7f97a8ff182.pdf -->
      <iframe :src="item.file_url" frameborder="0" width="100%" height="800px"></iframe>
    </div>
    <!-- IMG -->
    <div class="img" v-else>
      <!-- <p>{{item.file_name}}</p> -->
      <!-- /carousel-img/carousel1.jpeg -->
      <img :src="item.file_url" alt="" class="task-img"/>
    </div>
  </div>
  
  <div id="task-details">
    <div class="task-conts">
        <div class="task-title">实训系统</div>
        <div class="task-bor"></div>
        <div style="padding-top: 10px;">
            <div class="task-div-tabel">已选择的系统：</div>
            <div class="task-div-cont" v-for="item in schoolList" :key="item.id" > {{item.system_name}}</div>
        </div>
    </div>
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import { GetTaskDetail } from "@/api/task.js"; // 详情 编辑 地址
import { useRoute } from "vue-router";
import PageLoading from "@/components/PageLoading/index.vue";

const { SUCCESS } = resStatusEnum;
const loading = ref(false);

const route = useRoute();
const id = route.query.id;

const fileList = ref([])
const schoolList = ref([])

// 数据初始化
function InitDataMethod(){
  loading.value = true;
  let param = {"Task[id]":id}
  GetTaskDetail(param)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        fileList.value = res.data.upload_list   // 文件列表
        schoolList.value = res.data.system      // 学校列表
        // console.log("数据初始化:",schoolList.value)
      }
    })
    .catch((reject) => {});
};
InitDataMethod();

const src = ref('http://uu-shixun.oss-cn-hangzhou.aliyuncs.com/qddir/8719f9e9140bd347b5aeb7f97a8ff182.pdf') // pdf文件地址

</script>

<style scoped>
#task-details {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  margin-top: 20px;
}
.task-div-tabel{
  display: inline-block;
  width: 120px;
  position: absolute;
  line-height: 30px;
}
.task-title{
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
}
.task-bor{
  position: absolute;
  border-bottom: 1px solid #ddd;
  left: 0;
  right:0
}
.task-div-cont{
  position: relative;
  left: 105px;
  line-height: 30px;
  
}
.task-conts{
  border: 1px solid #ddd;
  padding:20px;
  position: relative;
}
.task-img{
  width: 100%;
  height: auto;
}
</style>